<template>
  <div class="offer-add">
    <div class="main-Title bgff"><h2>Add offer</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form ref="postData" :model="postData" :rules="rules" label-width="150px">
          <el-form-item label="offer ID" prop="offerId"
            ><el-input v-model="postData.offerId" placeholder="请输入offer ID"
          /></el-form-item>
          <el-form-item label="Title" prop="title"
            ><el-input v-model="postData.title" placeholder="请输入title"
          /></el-form-item>
          <el-form-item label="AppName" prop="appName"
            ><el-input v-model="postData.appName" placeholder="请输入appName"
          /></el-form-item>
          <el-form-item label="tracking link" prop="trackingLink">
            <el-input
              type="textarea"
              placeholder="请输入tracking link"
              :autosize="{ minRows: 5, maxRows: 10 }"
              v-model="postData.trackingLink"
            ></el-input>
          </el-form-item>
          <el-form-item label="国家" prop="country">
            <el-select
              v-model="postData.country"
              placeholder="Please select Country"
              style="width: 100%"
            >
              <el-option
                v-for="item in options.country"
                :key="item.id"
                :value="item.countryCode"
                :label="item.countryCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上游名称" prop="advertiserName">
            <el-select
              v-model="postData.advertiserName"
              placeholder="Please select advertiser"
              style="width: 100%"
            >
              <el-option
                v-for="item in options.advertiser"
                :key="item.id"
                :label="item.advertiserName"
                :value="item.advertiserName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Price" prop="price"
            ><el-input v-model="postData.price" placeholder="请输入price"
          /></el-form-item>
          <el-form-item label="点击cap" prop="clickCap"
            ><el-input v-model="postData.clickCap" placeholder="请输入Click Cap"
          /></el-form-item>

          <el-form-item label="OS" prop="os">
            <el-select v-model="postData.os" placeholder="Please select OS" style="width: 100%">
              <el-option
                v-for="item in options.os"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="postData.status"
              placeholder="Please select status"
              style="width: 100%"
            >
              <el-option
                v-for="item in options.status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="source_type" prop="sourceType">
            <el-select
              v-model="postData.sourceType"
              placeholder="Please select status"
              style="width: 100%"
            >
              <el-option
                v-for="item in options.sourceType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button
                :loading="loading.btn"
                type="success"
                style="width: 100%;"
                @click="add('postData')"
                >保存</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button :loading="loading.btn" type="primary" style="width: 100%;" @click="goOff()"
                >取消</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import optionData from '@/assets/js/optionData';
  import { handleOfferAdd } from '@/api/DDJ/v3/offer.js';
  import { getAdvertiserList } from '@/api/DDJ/v3/advertiser/index.js';
  import { fetchCountryList } from '@/api/DDJ/v3/country.js';
  export default {
    data() {
      return {
        postData: {
          id: this.$route.query.id,
        },
        options: {
          country: [],
          advertiser: [],
          os: optionData.osOption,
          status: [
            { label: 'active', value: 'active' },
            { label: 'paused', value: 'paused' },
          ],
          sourceType: [
            { label: 'api', value: 'api' },
            { label: 'shoudong', value: 'shoudong' },
          ],
        },
        loading: {
          btn: false,
        },
        rules: {
          // nodeId: [
          //   {
          //     required: true,
          //     message: '请选择节点',
          //     trigger: 'blur',
          //   },
          // ],
        },
      };
    },
    mounted() {
      this.getAdvertiser();
      this.getCountry();
    },
    created() {},
    methods: {
      // 获取Advertiser
      getAdvertiser() {
        getAdvertiserList().then((res) => {
          console.log(res);
          this.options.advertiser = res.result;
        });
      },
      // 获取country
      getCountry() {
        fetchCountryList().then((res) => {
          this.options.country = res.result;
        });
      },
      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading.btn = true;
          handleOfferAdd(this.postData).then((res) => {
            console.log(res);
            this.$message({
              message: '更新成功',
              type: 'success',
            });
            this.loading.btn = false;
            this.$router.push({
              path: '/click/offer/v3',
            });
          });
        });
      },
      goOff() {
        this.$router.push({
          path: '/click/offer/v3',
        });
      },
    },
  };
</script>

<style></style>
